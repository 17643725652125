import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import GeladaClient from '../geladaClient';

export const FORGOT_PASSWORD = gql`
  mutation($email:String!){
  forgotPassword(email:$email)
}
`


const ForgotPasswordMutation = (props:any) => {
  const { onComplete,email } = props;
  const [client] = useMutation(FORGOT_PASSWORD,{client: GeladaClient(false)});

  client({variables:{email}}).then(c=>
  {
    onComplete(c,"data");
  }).catch(c=>onComplete(c,"error"));
  return null;
  
}


export default ForgotPasswordMutation;
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import GeladaClient from '../geladaClient';

export const SIGN_UP = gql`
  mutation($user:RegisterUserInputType!,$credential:CredentialInput!){
  register(user:$user,credential:$credential)
}
`

const SignUpMutation = (props:any) => {
  const { onComplete,user,credential,onStart} = props;
  if(onStart)
    onStart();
  const [client] = useMutation(SIGN_UP,{client: GeladaClient(false)});

  client({variables:{user,credential}}).then(c=>
  {
    onComplete(c,"data");
  }).catch(c=>onComplete(c,"error"));
  return null;
  
}


export default SignUpMutation;
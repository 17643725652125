import gql from 'graphql-tag';

export const ACCEPT_INVITATION = gql`
mutation($token:String!,$communityName:String,$firstname:String,$lastname:String,$password:String){
    acceptInvitation(acceptInvitation:{tokenId:$token,communityName:$communityName ,firstName:$firstname,lastName:$lastname,password:$password}) {
      refreshToken
      token
      user{
        id
        firstname
        created
        lastname
        communityName
        credential {
          email
        }
      }
    }
  }
`
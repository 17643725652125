import { decode } from './decode'
import { TokenType } from './type'

export function verify(token: TokenType) {
  if (Date.now() >= token.exp * 1000) {
    return false
  }
  return true
}

export function decodeAndVerify(token: string) {
  return verify(decode(token))
}

import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import GeladaClient from '../geladaClient';

export const RESEND_ACTIVATION_LINK = gql`
 mutation($email:String!){
  resendActivationCode(email:$email)
}
`

const ResendActivationCodeMutation = (props:any) => {
  const { onComplete,email } = props;
  const [client] = useMutation(RESEND_ACTIVATION_LINK,{client: GeladaClient(false)});

  client({variables:{email}}).then(c=>
  {
    onComplete(c,"data");
  }).catch(c=>onComplete(c,"error"));
  return null;
  
}


export default ResendActivationCodeMutation;

import gql from 'graphql-tag';

export const GET_INVITATION = gql`
query($token:String!){
    getInvitation(token:$token){
      created
      customMessage
      emailUserInvited
      firstName
      lastName
      type
      
    }
  }
`;
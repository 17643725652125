import gql from 'graphql-tag'
import GeladaClient from '../geladaClient'
import { useOnPremQuery } from '../../_graphQL/useOnPremQuery'
import { _Store } from '../..'
import { TemporaryToken } from '../../utils/constants'

export const GET_TEMPORARY_TOKEN = gql`
  query {
    getTemporarySession
  }
`

const GetTemporaryTokenQuery = (props: any) => {
  const { onComplete } = props
  const client = useOnPremQuery(GET_TEMPORARY_TOKEN, GeladaClient())
  client({})
    .then((c) => {
      _Store(c.data.getTemporarySession, TemporaryToken)
      onComplete(c, 'data')
    })
    .catch((c) => onComplete(c, 'error'))
  return null
}

export default GetTemporaryTokenQuery


import gql from 'graphql-tag';
import GeladaClient from '../geladaClient';
import { useOnPremQuery } from '../../_graphQL/useOnPremQuery';

export const GET_INVITATION_FOR_USER = gql`
query{
    invitation{
      getInvitationForUser{
        created
      }
    }
  }
`;

const GetInvitationForUserQuery = (props:any) => {
    const { onComplete } = props;
    const client = useOnPremQuery(GET_INVITATION_FOR_USER,GeladaClient());
    client({}).then(c=>
    {
      onComplete(c,"data");
    }).catch(c=>onComplete(c,"error"));
    return null;
  };
  
  export default GetInvitationForUserQuery;
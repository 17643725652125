import gql from 'graphql-tag'
import GeladaClient from '../geladaClient'
import { useOnPremQuery } from '../../_graphQL/useOnPremQuery'
import { StoreUserData, StoreTokens } from '../../storage/cookieStorage'

export const LOGIN = gql`
  query($login: String!, $password: String!) {
    login(login: $login, password: $password) {
      token
      refreshToken
      user {
        id
        firstname
        created
        lastname
        communityName
        credential {
          email
        }
      }
    }
  }
`

const LoginQuery = (props: any) => {
  const { onComplete, login, password, customBuildGuid } = props
  const client = useOnPremQuery(LOGIN, GeladaClient(false, customBuildGuid))
  client({ login, password })
    .then((c) => {
      StoreUserData(c.data.login.user)
      StoreTokens(c.data.login.token, c.data.login.refreshToken)
      onComplete(c, 'data')
    })
    .catch((c) => onComplete(c, 'error'))
  return null
}

export default LoginQuery

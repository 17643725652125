import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import GeladaClient from '../geladaClient';

export const UPDATE_PASSWORD = gql`
  mutation($cPassword:String!,$nPassword:String!){
  user{
    updatePassword(currentPassword:$cPassword,newPassword:$nPassword)
  }
}
`

const UpdatePasswordMutation = (props:any) => {
  const { onComplete,user,credential } = props;
  const [client] = useMutation(UPDATE_PASSWORD,{client: GeladaClient()});

  client({variables:{user,credential}}).then(c=>
  {
    onComplete(c,"data");
  }).catch(c=>onComplete(c,"error"));
  return null;
  
}


export default UpdatePasswordMutation;
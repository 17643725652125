
import gql from 'graphql-tag';
import GeladaClient from '../geladaClient';
import { useOnPremQuery } from '../../_graphQL/useOnPremQuery';

export const GET_LOGGED_USER = gql`
  query {
    user {
      getLoggedUser {
        id
        firstname
        lastname
        credential {
          email
          secondaryEmail
        }
      }
    }
  }
`;

const GetLoggedUserQuery = (props:any) => {
    const { onComplete } = props;
    const client = useOnPremQuery(GET_LOGGED_USER,GeladaClient());
    client({}).then(c=>
    {
      onComplete(c,"data");
    }).catch(c=>onComplete(c,"error"));
    return null;
  };
  
  export default GetLoggedUserQuery;
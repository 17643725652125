import {  HttpLink } from "apollo-link-http";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import GeladaRefreshToken from "./geladaRefreshToken";

export default function GeladaClient (requiredToken:boolean=true,customBuild:string=""){

        var buildGuid = process.env.REACT_APP_PROJECT_ID===undefined ? "" : process.env.REACT_APP_PROJECT_ID;
        if(buildGuid==="")
          buildGuid=customBuild;
        const cache = new InMemoryCache();
        const link = new HttpLink({
          uri: process.env.REACT_APP_GELADA_API_URL,
          headers:{
            Authorization: requiredToken? GeladaRefreshToken() : "",
            Custom_build_guid:buildGuid,
            'Content-Type': 'application/graphql',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true,
          }
        });

        const client = new ApolloClient({
          cache: cache,
          link: link,

          name: 'react-web-client',
          version: '1.3',
          queryDeduplication: false,
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'cache-and-network',
            },
          },
        });
     
      
      return client;
}
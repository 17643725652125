import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import GeladaClient from '../geladaClient';
import { StoreTokens } from '../../storage/cookieStorage';

export const ACTIVATE_ACCOUNT = gql`
  mutation($activationCode:String!){
  activateAccount(activationCode:$activationCode){
    token
    refreshToken
    user{
      id
      firstname
      lastname
      communityName
      credential {
        email
      }
    }
  }
}
`

const ActiveAccountMutation = (props:any) => {
  const { onComplete,activationCode } = props;
  const [client] = useMutation(ACTIVATE_ACCOUNT,{client: GeladaClient(false)});

  client({variables:{activationCode}}).then(c=>
  {
    StoreTokens(c.data.activateAccount.token,c.data.activateAccount.refreshToken);
    onComplete(c,"data");
  }).catch(c=>onComplete(c,"error"));
  return null;
  
}


export default ActiveAccountMutation;
import gql from 'graphql-tag';
import GeladaClient from '../geladaClient';
import { useOnPremQuery } from '../../_graphQL/useOnPremQuery';
import { StoreTokens } from '../../storage/cookieStorage';

export const REFRESH_TOKEN = gql`
  query($refreshToken:String!){
  user{
    refreshToken(refreshToken:$refreshToken){
      refreshToken
      token
    }
  }
}
`

const RefreshTokenQuery = (props:any) => {
  const { onComplete,refreshToken } = props;
  const client = useOnPremQuery(REFRESH_TOKEN,GeladaClient(false));

  client({refreshToken}).then(c=>
  {
    StoreTokens(c.data.user.refreshToken.token,c.data.user.refreshToken.refreshToken);
    onComplete(c,"data");
  }).catch(c=>onComplete(c,"error"));
  return null;

};

export default RefreshTokenQuery;
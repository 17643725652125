import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import GeladaClient from '../geladaClient';

export const FORGOT_PASSWORD_UPDATE = gql`
 mutation($code:String!,$newPassword:String!){
forgotPasswordUpdate(code:$code,newPassword:$newPassword)
}

`


const ForgotPasswordUpdateMutation = (props:any) => {
    const { onComplete,code,newPassword } = props;
    const [client] = useMutation(FORGOT_PASSWORD_UPDATE,{client: GeladaClient(false)});
  
    client({variables:{code,newPassword}}).then(c=>
    {
      onComplete(c,"data");
    }).catch(c=>onComplete(c,"error"));
    return null;
    
  }
  
  
  export default ForgotPasswordUpdateMutation;
import { REFRESH_TOKEN } from './queries/RefreshToken'
import GeladaClient from './geladaClient'
import {
  GetRefreshToken,
  GetAccessToken,
  ClearTokens,
  StoreTokens,
  GetTemporaryToken,
  StoreTemporaryToken,
  ClearTemporary
} from '../storage/cookieStorage'
import { GET_TEMPORARY_TOKEN } from './queries/GetTemporaryToken'
import { decodeAndVerify } from '../utils/token/verify'

export function TemporaryToken(): string | undefined {
  if (GetAccessToken() !== undefined) return undefined

  const token = GetTemporaryToken()
  if (token !== undefined && decodeAndVerify(token)) return token
  GeladaClient(false)
    .query({ query: GET_TEMPORARY_TOKEN })
    .then((c: any) => {
      if (c.data) StoreTemporaryToken(c.data.getTemporarySession)
    })
    .catch((c: any) => {
      console.warn(c)
      ClearTokens()
    })
  return GetTemporaryToken()
}
export default function GeladaRefreshToken(): string | undefined {
  const accessToken = GetAccessToken()
  if (accessToken !== undefined) {
    if (decodeAndVerify(accessToken) === false) {
      var refreshToken = GetRefreshToken()
      if (refreshToken === undefined) return undefined

      if (decodeAndVerify(refreshToken) === false) {
        ClearTokens()
        return undefined
      }

      GeladaClient(false)
        .query({
          query: REFRESH_TOKEN,
          variables: { refreshToken: refreshToken }
        })
        .then((c: any) => {
          if (c.data) {
            StoreTokens(
              c.data.user.refreshToken.token,
              c.data.user.refreshToken.refreshToken
            )
            ClearTemporary()
          }
          if (c.errors) {
            ClearTokens()
          }
        })
        .catch((c: any) => {
          console.warn(c)
          ClearTokens()
        })
    }
    return GetAccessToken()
  }

  return undefined
}

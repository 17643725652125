/* eslint-disable camelcase */
import LoginQuery, { LOGIN } from './middleware/queries/Login'
import RefreshTokenQuery, {
  REFRESH_TOKEN
} from './middleware/queries/RefreshToken'
import ActiveAccountMutation, {
  ACTIVATE_ACCOUNT
} from './middleware/mutations/ActivateAccount'
import GeladaRefreshToken, {
  TemporaryToken
} from './middleware/geladaRefreshToken'
import GeladaClient from './middleware/geladaClient'
import ForgotPasswordMutation, {
  FORGOT_PASSWORD
} from './middleware/mutations/ForgotPassword'
import ForgotPasswordUpdateMutation, {
  FORGOT_PASSWORD_UPDATE
} from './middleware/mutations/ForgotPasswordUpdate'
import ResendActivationCodeMutation, {
  RESEND_ACTIVATION_LINK
} from './middleware/mutations/ResendActivationCode'
import SignUpMutation, { SIGN_UP } from './middleware/mutations/SignUp'
import UpdatePasswordMutation, {
  UPDATE_PASSWORD
} from './middleware/mutations/UpdatePassword'
import GetLoggedUserQuery, {
  GET_LOGGED_USER
} from './middleware/queries/GetLoggedUser'
import {
  StoreTokens,
  ClearTokens,
  GetAccessToken,
  GetRefreshToken,
  GetUserData,
  Store,
  GetStored,
  ClearStorage,
  ClearTemporary
} from './storage/cookieStorage'
import { eStorageKey } from './enumerators/eStorageKey'
import { ACCEPT_INVITATION } from './middleware/mutations/AcceptInvitation'
import { GET_INVITATION } from './middleware/queries/GetInvitation'
import GetInvitationForUserQuery from './middleware/queries/GetInvitationForUser'
export const ActivateAccount = ActiveAccountMutation
export const ForgotPassword = ForgotPasswordMutation
export const ForgotPasswordUpdate = ForgotPasswordUpdateMutation
export const ResendActivationCode = ResendActivationCodeMutation
export const SignUp = SignUpMutation
export const UpdatePassword = UpdatePasswordMutation
export const GetLoggedUser = GetLoggedUserQuery

export const GetInvitationForUser = GetInvitationForUserQuery
export const Login = LoginQuery
export const Refresh = RefreshTokenQuery
export const GetInvitation_Query = GET_INVITATION
export const AcceptInvitation_Mutation = ACCEPT_INVITATION
export const GetTemporaryToken = TemporaryToken
export const ActivateAccount_Mutation = ACTIVATE_ACCOUNT
export const Login_Query = LOGIN
export const RefreshToken_Query = REFRESH_TOKEN
export const ForgotPassword_Mutation = FORGOT_PASSWORD
export const ForgotPasswordUpdate_Mutation = FORGOT_PASSWORD_UPDATE
export const ResendActivationCode_Mutation = RESEND_ACTIVATION_LINK
export const SignUp_Mutation = SIGN_UP
export const UpdatePassword_Mutation = UPDATE_PASSWORD
export const GetLoggedUser_Query = GET_LOGGED_USER
export const _eStorageKey = eStorageKey

export const _Store = Store
export const _GetStored = GetStored
export const _StoreTokens = StoreTokens
export const _ClearTokens = ClearTokens
export const _ClearStorage = ClearStorage
export const _GetAccessToken = GetAccessToken
export const _ClearTemporary = ClearTemporary
export const _GetRefreshToken = GetRefreshToken
export const _GetUserData = GetUserData

export const Token = GeladaRefreshToken()

export const Client = (requireToken: boolean) => GeladaClient(requireToken)

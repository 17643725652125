import {  OperationVariables } from '@apollo/react-common'
import { DocumentNode } from 'graphql';
import React from 'react';
import ApolloClient from 'apollo-client';

export function useOnPremQuery<TData = any, TVariables = OperationVariables>(query: DocumentNode,client:ApolloClient<object>) {
    return React.useCallback(
        (variables: TVariables) =>
            client.query<TData, TVariables>({
                query: query,
                variables: variables,
            }),
        [client]
    );
}

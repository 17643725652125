import Cookies from 'universal-cookie'
import { eStorageKey } from '../enumerators/eStorageKey'
import { decode } from '../utils/token/decode'
import { decodeAndVerify } from '../utils/token/verify'
import { getDate } from '../utils/token/date'
import { TokenTypes } from '../utils/token/type'

import * as CookieNames from '../utils/constants'

const cookies = new Cookies()

type CookieTypes = TokenTypes | 'UserData'

const path = '/'

// Create
function store(c: any, key: CookieTypes, opt = {}) {
  cookies.set(key.toString(), c, { path, ...opt })
}

export const StoreTokens = (token: string, refreshToken: string) => {
  store(token, CookieNames.AccessToken, {
    expires: getDate(decode(token), 'exp')
  })
  store(refreshToken, CookieNames.RefreshToken, {
    expires: getDate(decode(refreshToken), 'exp')
  })
}

export const StoreUserData = (data: any) => {
  store(data, CookieNames.UserData)
}

export const StoreTemporaryToken = (token: string) => {
  store(token, CookieNames.TemporaryToken)
}

// @deprecated
export const Store = (...params: any) => {
  console.warn(
    'Method Store is deprecated. Use StoreTokens, StoreUserData, or StoreTemporaryToken'
  )
  // @ts-ignore
  store(...params)
}

// Read
function getCookie(key: CookieTypes) {
  const token = cookies.get(key)
  if (key === CookieNames.UserData) return token
  if (token && decodeAndVerify(token)) return token
}

export const GetTemporaryToken = () => {
  return getCookie(CookieNames.TemporaryToken)
}

export const GetAccessToken = () => {
  return getCookie(CookieNames.AccessToken)
}

export const GetRefreshToken = () => {
  return getCookie(CookieNames.RefreshToken)
}

export const GetUserData = () => {
  return getCookie(CookieNames.UserData)
}

// @deprecated
export const GetStored = (key: any): undefined | any => {
  console.warn(
    'Method GetStored is deprecated. Use GetTemporaryToken, GetAccessToken, GetRefreshToken, or GetUserData'
  )
  return getCookie(eStorageKey[key] || key)
}

// Delete
export const ClearTemporary = () => {
  cookies.remove(CookieNames.TemporaryToken, { path })
}

export const ClearTokens = () => {
  cookies.remove(CookieNames.AccessToken, { path })
  cookies.remove(CookieNames.RefreshToken, { path })
}

export const ClearStorage = () => {
  ;[
    CookieNames.RefreshToken,
    CookieNames.AccessToken,
    CookieNames.TemporaryToken,
    CookieNames.UserData
  ].forEach((key) => cookies.remove(key, { path }))
}
